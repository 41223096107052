import { Card, useBreakpoints } from '@shopify/polaris'
import { ArcElement, Chart, ChartOptions, DoughnutController } from 'chart.js'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { selectShopCurrency } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { ChartName } from './ChartName'
import { DataRow } from './DataRow'
import { DataRows } from './DataRows'
import { DASHBOARD_PALETTE } from './ReferenceColor'
import { ReferencesPlaceholder } from './ReferencesPlaceholder'
import { CHART_PLACEHOLDER_COLOR } from './dashboard-values'

Chart.register(DoughnutController, ArcElement)

type DonutChartData = {
  name: string
  count: number
  paletteIndex: number
  path?: string
}[]

type DonutChartProps = {
  name: ReactNode
  data: DonutChartData
  money?: boolean
}

export const DonutChart: FC<DonutChartProps> = ({ name, data, money }) => {
  const { lgDown, mdDown } = useBreakpoints()

  const shopCurrency = _(selectShopCurrency)

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const [trustHighlight, setTrustHighlight] = useState(true)

  const totalCount = useMemo(() => data.map((s) => s.count).reduce((result, count) => result + count, 0), [data])
  const extendedData = useMemo<((typeof data)[number] & { percent: number })[]>(
    () =>
      data.map((s) => ({
        ...s,
        percent: Math.round((s.count / totalCount) * 100),
      })),
    [data, totalCount],
  )

  const labels = useMemo<string[]>(() => data.map((row) => row.name), [data])
  const counts = useMemo<number[]>(() => data.map((row) => row.count), [data])
  const chartPalette = useMemo(() => data.map((row) => DASHBOARD_PALETTE[row.paletteIndex]), [data])

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [chart, setChart] = useState<Chart>()
  useEffect(() => {
    if (canvas) {
      const chart = new Chart(canvas, {
        type: 'doughnut',
        options: {
          cutout: 60,
          layout: {
            padding: 1,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          onHover: (event, elements) => {
            if (elements.length) setHighlightedIndex(elements[0].index)
            else setHighlightedIndex(null)
          },
          animations: {
            borderWidth: {
              duration: 100,
              easing: 'easeInOutCubic',
            },
          },
        } as ChartOptions,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              borderJoinStyle: 'miter',
              borderWidth: 0,
              borderColor: chartPalette,
              backgroundColor: chartPalette,
            },
          ],
        },
      })
      setChart(chart)
      return () => {
        setChart(undefined)
        chart.destroy()
      }
    }
  }, [canvas])

  useEffect(() => {
    if (chart?.canvas) {
      chart.data.labels = labels
      chart.data.datasets[0].data = counts
      chart.data.datasets[0].borderColor = chartPalette
      chart.data.datasets[0].backgroundColor = chartPalette

      const borderWidths = new Array(counts.length).fill(0)
      if (trustHighlight && highlightedIndex !== null) borderWidths[highlightedIndex] = 2
      chart.data.datasets[0].borderWidth = borderWidths

      chart.update()
    }
  }, [chart, labels, counts, chartPalette, trustHighlight, highlightedIndex])

  return (
    <Container columnSpan={mdDown ? 6 : lgDown ? 3 : 2}>
      <Card>
        <Top>
          <CanvasWrapper $gone={!extendedData.length}>
            <canvas
              ref={setCanvas}
              onMouseEnter={() => setTrustHighlight(true)}
              onMouseLeave={() => setTrustHighlight(false)}
            ></canvas>
          </CanvasWrapper>
          {!extendedData.length && <ChartPlaceholder />}
          <ChartName>{name}</ChartName>
        </Top>
        <DataRows>
          {extendedData.length ? (
            extendedData.map((s, i) => (
              <DataRow
                key={i}
                index={s.paletteIndex}
                name={s.name}
                value={
                  <>
                    {money ? <FormattedNumber value={s.count} style="currency" currency={shopCurrency} /> : s.count} (
                    {s.percent}%)
                  </>
                }
                path={s.path}
                highlight={trustHighlight && i === highlightedIndex}
                onActive={() => {
                  setTrustHighlight(true)
                  setHighlightedIndex(i)
                }}
                onLeft={() => setHighlightedIndex((current) => (current === i ? null : current))}
              />
            ))
          ) : (
            <ReferencesPlaceholder />
          )}
        </DataRows>
      </Card>
    </Container>
  )
}

const Top = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 12px;
  padding-bottom: var(--p-space-500);
  border-bottom: 1px solid var(--p-color-border-secondary);
  margin-bottom: calc(var(--p-space-100) * -1);
`

const CanvasWrapper = styled.div<{ $gone: boolean }>`
  width: 134px;
  height: 134px;
  position: relative;
  ${(p) =>
    p.$gone &&
    css`
      display: none;
    `}
`

const ChartPlaceholder = styled.div`
  width: 134px;
  height: 134px;
  border: 6px solid ${CHART_PLACEHOLDER_COLOR};
  border-radius: 50%;
`

const Container = styled.div<{ columnSpan: number }>`
  grid-column: span ${(p) => p.columnSpan};
`
