import '@shopify/app-bridge'
import { NavMenu } from '@shopify/app-bridge-react'
import { refreshTraceId } from 'graphQLClient'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

export const ShopifyNavigation: FC = () => {
  const location = useLocation()

  // Update traceId
  useEffect(() => refreshTraceId(location.pathname), [location.pathname])

  // Render App Bridge navigation
  return (
    <div style={{ display: 'none' }}>
      <NavMenu>
        <Link to="/" rel="home">
          <FormattedMessage id="shopifyNavigation.home" defaultMessage="Home" />
        </Link>
        <Link to="/returns">
          <FormattedMessage id="shopifyNavigation.returns" defaultMessage="Returns" />
        </Link>
        <Link to="/config-settings">
          <FormattedMessage id="shopifyNavigation.settings" defaultMessage="Settings" />
        </Link>
        <Link to="/help-and-support">
          <FormattedMessage id="shopifyNavigation.help" defaultMessage="Help" />
        </Link>
      </NavMenu>
    </div>
  )
}
