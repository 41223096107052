import ScrollToElement from 'ScrollToElement'
import { FC, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { SkeletonSettings } from './SkeletonSettings'
import { LanguageSettingsPageLoader } from './sections/LanguageSettingsPageLoader'
import { PortalSettingsPageLoader } from './sections/PortalSettingsPageLoader'
import { WorkflowSettingsPageLoader } from './sections/WorkflowSettingsPageLoader'
import { PortalCustomizerPageLoader } from './sections/customizer/PortalCustomizerPageLoader'
import { IntegrationSettingsPageLoader } from './sections/integrations/IntegrationsSettingsPageLoader'
import { LogisticsIntegrationsSettingsPageLoader } from './sections/integrations/LogisticsIntegrationsSettingsPageLoader'
import { EditNotificationPageLoader } from './sections/notifications/EditNotificationPageLoader'
import { NotificationsSettingsPageLoader } from './sections/notifications/NotificationsSettingsPageLoader'
import { PlansPageLoader } from './sections/plans/PlansPageLoader'
import { PolicySettingsPageLoader } from './sections/policy/PolicySettingsPageLoader'
import { RulesEditPage } from './sections/rules/RulesEditPage'
import { RulesEditPageLoader } from './sections/rules/RulesEditPageLoader'
import { RulesPageLoader } from './sections/rules/RulesPageLoader'
import { ShippingSettingsPageLoader } from './sections/shipping/ShippingSettingsPageLoader'

const SettingsMainPage = lazy(() => import('./sections/SettingsPage'))

export const SettingsRouter: FC = () => {
  return (
    <>
      <ScrollToElement />
      <Suspense fallback={<SkeletonSettings />}>
        <Routes>
          <Route path="" element={<SettingsMainPage />} />
          <Route path="/policy" element={<PolicySettingsPageLoader />} />
          <Route path="/notifs" element={<NotificationsSettingsPageLoader />} />
          <Route path="/notifs/:notificationId" element={<EditNotificationPageLoader />} />
          <Route path="/notifs/:notificationId/:locale" element={<EditNotificationPageLoader />} />
          <Route path="/shipping" element={<ShippingSettingsPageLoader />} />
          <Route path="/workflow" element={<WorkflowSettingsPageLoader />} />
          <Route path="/rules" element={<RulesPageLoader />} />
          <Route path="/rules/create" element={<RulesEditPage />} />
          <Route path="/rules/:tab" element={<RulesPageLoader />} />
          <Route path="/rules/edit/:ruleId" element={<RulesEditPageLoader />} />
          <Route path="/language" element={<LanguageSettingsPageLoader />} />
          <Route path="/integrations" element={<IntegrationSettingsPageLoader />} />
          <Route path="/logistics-integrations" element={<LogisticsIntegrationsSettingsPageLoader />} />
          <Route path="/portal" element={<PortalSettingsPageLoader />} />
          <Route path="/portal/test" element={<PortalCustomizerPageLoader />} />
          <Route path="/portal/customize" element={<PortalCustomizerPageLoader />} />
          <Route path="/billing" element={<PlansPageLoader force={false} />} />
        </Routes>
      </Suspense>
    </>
  )
}
