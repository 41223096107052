import { EventHandler, FC, FocusEvent, MouseEvent, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { DEFAULT_EASING } from 'onboarding/onboarding-styles'

import { ReferenceColor } from './ReferenceColor'

type ActiveReturnsLegendProps = {
  index?: number
  palette?: string[]
  name: ReactNode
  value: ReactNode
  url?: string
  path?: string
  dim?: boolean
  highlight?: boolean
  highlightLeft?: string
  dotOnly?: boolean
  onActive?: EventHandler<MouseEvent | FocusEvent>
  onLeft?: EventHandler<MouseEvent | FocusEvent>
}

export const ActiveReturnsLegend: FC<ActiveReturnsLegendProps> = ({
  index,
  palette,
  name,
  value,
  url,
  path,
  dim,
  highlight,
  highlightLeft,
  dotOnly,
  onActive,
  onLeft,
}) => {
  const navigate = useNavigate()
  return (
    <Link
      href={url || path}
      className={dotOnly ? 'dot-only' : ''}
      target={url ? '_blank' : undefined}
      onClick={
        !url && path
          ? (e) => {
              e.preventDefault()
              navigate(path)
            }
          : undefined
      }
      $dim={dim}
      $highlight={highlight}
      forcedLeft={highlight && highlightLeft ? `${highlightLeft}` : undefined}
      tabIndex={0}
      onMouseEnter={onActive}
      onFocus={onActive}
      onMouseLeave={onLeft}
      onBlur={onLeft}
    >
      <Offsetter $offsetSize={highlight} $noTransition={!!highlightLeft}>
        {index !== undefined && (
          <Bullet>
            <ReferenceColor index={index} palette={palette} />
          </Bullet>
        )}
        <Name>{name}</Name>
        <Value>{value}</Value>
      </Offsetter>
    </Link>
  )
}

export const LEGEND_BULLET_DIAMETER = 7
export const LEGEND_HORIZONTAL_PADDING = 12

const Bullet = styled.span`
  flex: 0 0 auto;
  display: block;
  position: relative;
  width: ${LEGEND_BULLET_DIAMETER}px;
  height: ${LEGEND_BULLET_DIAMETER}px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${LEGEND_HORIZONTAL_PADDING}px;
`

const Name = styled.span`
  flex: 1 1 0;
  display: block;
  margin-right: ${LEGEND_HORIZONTAL_PADDING}px;
`

const Value = styled.span`
  display: block;
  flex: 0 0 auto;
  color: var(--p-color-text-secondary);
`

const Offsetter = styled.span<{ $offsetSize?: boolean; $noTransition: boolean }>`
  padding: 3px ${LEGEND_HORIZONTAL_PADDING}px;
  display: flex;
  box-sizing: content-box;
  min-height: var(--p-font-line-height-500);
  align-items: center;
  border-radius: 4px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    height: 10px;
    left: 0;
    right: 0;
  }

  ${(p) =>
    p.$offsetSize &&
    css`
      transform: translateX(${LEGEND_HORIZONTAL_PADDING}px);
      background: var(--p-color-bg-surface-hover);
      z-index: 2;
    `}
  ${(p) =>
    !p.$noTransition &&
    css`
      transition:
        background-color 0.1s ${DEFAULT_EASING},
        transform 0.1s ${DEFAULT_EASING};
    `}
`

const Link = styled.a<{ $highlight?: boolean; $dim?: boolean; forcedLeft?: string }>`
  position: absolute;
  top: 7px;
  color: inherit;
  text-decoration: none;
  margin: 0 -${LEGEND_HORIZONTAL_PADDING}px;
  white-space: nowrap;
  transition: opacity 0.1s ${DEFAULT_EASING};
  outline: 0;
  cursor: default;

  ${(p) =>
    p.href &&
    css`
      cursor: pointer;
    `};

  ${(p) =>
    !p.$highlight &&
    css`
      &.dot-only:not(.ignore-dot-only):not(:focus-visible) {
        ${Name}, ${Value} {
          display: none;
        }
        ${Bullet} {
          margin-right: 0;
        }
      }
    `}

  ${(p) =>
    p.$dim &&
    css`
      opacity: 0.3;
    `}

  ${(p) =>
    p.forcedLeft &&
    css`
      left: ${p.forcedLeft} !important;
    `}
`
