import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParamFilters } from './useParamFilters'

const DEFAULT_VIEW = 'active'

export const useCurrentView = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const paramFilters = useParamFilters()
  const currentView = searchParams.get('view') ?? (Object.keys(paramFilters).length ? undefined : DEFAULT_VIEW)
  const setCurrentView = useCallback(
    (view: string | null) => {
      if (view && view !== DEFAULT_VIEW) setSearchParams({ view }, { replace: true })
      else setSearchParams({}, { replace: true })
    },
    [setSearchParams],
  )
  return [currentView, setCurrentView] as const
}
