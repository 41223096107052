import { useCallback, useEffect, useState } from 'react'

import { isFunction, Setter } from 'utils'

const latestValues = new Map<string, any>()

const subscriptions = new Map<string, Set<Setter<any>>>()
const subscribe = (id: string, setter: Setter<any>) => {
  const keySetters = subscriptions.get(id) ?? new Set()
  keySetters.add(setter)
  subscriptions.set(id, keySetters)
  // Unsubscribe
  return () => {
    const keySetters = subscriptions.get(id)
    keySetters?.delete(setter)
    if (!keySetters?.size) {
      subscriptions.delete(id)
      latestValues.delete(id)
    }
  }
}

export const useSharedState = <Type>(id: string, defaultValue: Type) => {
  if (!latestValues.has(id)) latestValues.set(id, defaultValue)
  const [value, _setValue] = useState<Type>(latestValues.get(id))
  useEffect(() => subscribe(id, _setValue), [])
  const setValue: Setter<Type> = useCallback((valueOrFunction) => {
    if (isFunction(valueOrFunction)) {
      const updateFunction = valueOrFunction
      subscriptions.get(id)?.forEach((setter: Setter<Type>) =>
        setter((current) => {
          const newValue = updateFunction(current)
          latestValues.set(id, newValue)
          return newValue
        }),
      )
    } else {
      const newValue = valueOrFunction
      subscriptions.get(id)?.forEach((setter: Setter<Type>) => {
        latestValues.set(id, newValue)
        setter(newValue)
      })
    }
  }, [])
  return [value, setValue] as const
}
