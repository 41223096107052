import { useCallback, useMemo } from 'react'

import { useStickyState } from 'common/useStickyState'
import { DehydratedReturnsView, dehydrateViews, rehydrateViews, ReturnsView } from 'returns/models/view'
import { isFunction, Setter } from 'utils'

export const useCustomViews = () => {
  const [customViews, _setCustomViews] = useStickyState<DehydratedReturnsView[]>('rz-returns-table-custom-views', [])
  const hidratedCustomViews = useMemo(() => {
    const views = JSON.parse(JSON.stringify(customViews)) as DehydratedReturnsView[]
    return rehydrateViews(views)
  }, [customViews])
  const setCustomViews: Setter<ReturnsView[]> = useCallback((valueOrFunction) => {
    if (isFunction(valueOrFunction))
      _setCustomViews((current) => dehydrateViews(valueOrFunction(rehydrateViews(current))))
    else _setCustomViews(dehydrateViews(valueOrFunction))
  }, [])
  return [hidratedCustomViews, setCustomViews] as const
}
