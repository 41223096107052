import { useCallback } from 'react'

import { LOCALSTORAGE_IS_AVAILABLE } from 'config'
import { isFunction, Setter } from 'utils'

import { useSharedState } from './useSharedState'

const getStoredValue = <Type,>(key: string, defaultValue: Type) => {
  const stickyValue = LOCALSTORAGE_IS_AVAILABLE ? window.localStorage.getItem(key) : null
  return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
}

const updateStoredValue = (key: string, value: any) => {
  if (LOCALSTORAGE_IS_AVAILABLE) window.localStorage.setItem(key, JSON.stringify(value))
}

export function useStickyState<Type>(key: string, defaultValue: Type) {
  const [value, _setValue] = useSharedState<Type>(key, getStoredValue(key, defaultValue))
  const setValue: Setter<Type> = useCallback((valueOrFunction) => {
    if (isFunction(valueOrFunction)) {
      let newValue: Type
      _setValue((current) => {
        newValue = valueOrFunction(current)
        return newValue
      })
      updateStoredValue(key, newValue!)
    } else {
      const newValue = valueOrFunction
      updateStoredValue(key, newValue)
      _setValue(newValue)
    }
  }, [])
  return [value, setValue] as const
}
