import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useCurrentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const param = searchParams.get('currentPage')
  const currentPage = param ? parseInt(param) : 0
  const setCurrentPage = useCallback(
    (page: number) =>
      setSearchParams(
        (prev) => {
          if (page) prev.set('currentPage', page.toString())
          else prev.delete('currentPage')
          return prev
        },
        { replace: true },
      ),
    [setSearchParams],
  )
  return [currentPage, setCurrentPage] as const
}
