import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useStickyState } from 'common/useStickyState'
import { SortType } from 'returns/models/sort'

import { useCurrentView } from './useCurrentView'

const DEFAULT_SORT_BY: SortType = 'returnDate'

export const useSortBy = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const urlSortBy = searchParams.get('sortBy')
  const [lastSortBy, setLastSortBy] = useStickyState<SortType | null>('rz-last-sort-by', DEFAULT_SORT_BY)
  const [perViewSortBy, setPerViewSortBy] = useStickyState<{ [viewId: string]: SortType | undefined }>(
    'rz-per-view-sort-by',
    {},
  )
  const [currentView] = useCurrentView()
  const sortBy = (urlSortBy || (currentView && perViewSortBy[currentView]) || lastSortBy || DEFAULT_SORT_BY) as SortType
  const setSortBy = useCallback(
    (sort: SortType | null) => {
      setSearchParams(
        (prev) => {
          if (sort) prev.set('sortBy', sort)
          else prev.delete('sortBy')
          return prev
        },
        { replace: true },
      )
      setLastSortBy(sort)
      if (sort) {
        if (currentView) setPerViewSortBy({ ...perViewSortBy, [currentView]: sort })
      } else {
        if (currentView) {
          const newValue = { ...perViewSortBy }
          delete newValue[currentView]
          setPerViewSortBy(newValue)
        }
      }
    },
    [setSearchParams],
  )
  return [sortBy, setSortBy] as const
}
