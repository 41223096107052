import { Button, ButtonProps } from '@shopify/polaris'
import { FunctionComponent, useCallback } from 'react'
import { selectIsBeaconVisible } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { openExternalUrl, preventDefaultAndStop } from 'utils'

export interface ExternalButtonProps extends ButtonProps {
  url: string
  articleId?: string
}

/**
 * Opening up external link when embedded in shopify admin requires
 * some additional logic.
 **/

export const ExternalButton: FunctionComponent<ExternalButtonProps> = ({
  url,
  articleId,
  children,
  ...buttonConfig
}) => {
  const beaconVisible = _(selectIsBeaconVisible)
  const onClick = useCallback(() => {
    if (articleId && beaconVisible) window.Beacon('article', articleId)
    else openExternalUrl(url)
  }, [url, articleId, beaconVisible])
  return (
    <span onClick={preventDefaultAndStop}>
      <Button url={url} onClick={onClick} {...buttonConfig}>
        {children}
      </Button>
    </span>
  )
}
export default ExternalButton
