import { Dispatch, SetStateAction } from 'react'

export * from './hooks'

// Handy way to strip all the errors caused by <Maybe> generated types
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const isEmpty = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.length === 0
  } else {
    return value === '' || value === null || value === undefined
  }
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const toNumber = (s: string) => parseInt(s, 10)
export const toString = (n: number) => n.toString()

export const openExternalUrl = (url: string) => window.open(url, '_blank')?.focus()

// Shorten a string to less than maxLength characters without truncating words.
// https://stackoverflow.com/a/40382963/4915
export function shortenSentence(str: string, maxLength: number, separator = ' ') {
  if (str.length <= maxLength) return str
  return str.substr(0, str.lastIndexOf(separator, maxLength))
}

export function compareMaps<T, U>(map1: Map<T, U>, map2: Map<T, U>) {
  if (map1.size !== map2.size) return false
  for (const [key, val] of map1) {
    if (!map2.has(key)) return false
    if (map2.get(key) !== val) return false
  }
  return true
}

export const isNumber = (value: unknown) => {
  if (typeof value === 'boolean') return false
  if (typeof value === 'object') return false
  if (typeof value === 'undefined') return false
  if (typeof value === 'string' && value.trim() === '') return false
  return !isNaN(value as any)
}

export const areSetsEqual = (a: Set<unknown>, b: Set<unknown>) =>
  a.size === b.size && [...a].every((value) => b.has(value))

export const roundCents = <T,>(value: T) => {
  if (typeof value === 'number') return Math.round(value * 100) / 100
  else return value
}

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect()
  return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
}

export function log<T>(value: T): T {
  console.log(value)
  return value
}

export const decimalToNum = (value: any) => (isNaN(parseFloat(value)) ? 0 : parseFloat(value))

export const isFunction = <Type extends (...args: any[]) => any>(value: any): value is Type =>
  typeof value === 'function'

export const preventDefault = (e: MouseEvent | React.MouseEvent) => e.preventDefault()
export const stopPropagation = (e: MouseEvent | React.MouseEvent) => e.stopPropagation()
export const preventDefaultAndStop = (e: MouseEvent | React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

export type Implements<T, U extends T> = U

export const setsAreEqual = (xs: Set<unknown>, ys: Set<unknown>) =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x))

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export type Setter<Type> = Dispatch<SetStateAction<Type>>
