import { INDEXED_DB_NAME, LOCALSTORAGE_IS_AVAILABLE } from 'config'

const TOKEN_KEY = 'rz-token'
const LOGIN_AS_URL_KEY = 'rz-login-as-url'

export const setToken = (token: string) => {
  if (LOCALSTORAGE_IS_AVAILABLE) {
    indexedDB.deleteDatabase(INDEXED_DB_NAME)
    localStorage.clear()
    localStorage.setItem(TOKEN_KEY, token)
  }
}

export const deleteToken = () => {
  if (LOCALSTORAGE_IS_AVAILABLE) {
    indexedDB.deleteDatabase(INDEXED_DB_NAME)
    localStorage.clear()
  }
}

export const getToken = () => {
  if (!LOCALSTORAGE_IS_AVAILABLE) {
    return null
  }
  return localStorage.getItem(TOKEN_KEY)
}

export const setLoginAsUrl = (url: string) => {
  if (LOCALSTORAGE_IS_AVAILABLE) {
    localStorage.setItem(LOGIN_AS_URL_KEY, url)
  }
}

export const getLoginAsUrl = () => {
  if (!LOCALSTORAGE_IS_AVAILABLE) {
    return null
  }
  return localStorage.getItem(LOGIN_AS_URL_KEY)
}
