import * as Sentry from '@sentry/react'
import { Frame } from '@shopify/polaris'
import { GlobalToasts } from 'GlobalToasts'
import { LoginAsUrl } from 'LoginAsUrl'
import { NavigationLoader } from 'NavigationLoader'
import { ShopifyNavigation } from 'ShopifyNavigation'
import { CreateReturnPageLoader } from 'create-return/CreateReturnPageLoader'
import { FC, Suspense, useEffect } from 'react'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import RMALoader from 'rma/RMALoader'
import { selectShop, selectShouldAuthenticateUser } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import { selectOnboardingOpen } from 'store/onboarding/onboarding.selectors'
import { HelpAndSupportPageLoader } from 'support/HelpAndSupportPageLoader'

import { getToken } from 'auth'
import { PageRevealer } from 'common/PageRevealer'
import { DashboardLoader } from 'dashboard/DashboardLoader'
import { OnboardingLoader } from 'onboarding/OnboardingLoader'
import { ReturnDetailPageLoader } from 'return/ReturnDetailPageLoader'
import { ReturnsPageLoader } from 'returns/ReturnsPageLoader'
import { SettingsRouter } from 'settings/SettingsRouter'
import { PlansPageLoader } from 'settings/sections/plans/PlansPageLoader'

import AuthenticateUser from './AuthenticateUser'
import Beacon from './Beacon'

const App: FC = () => {
  const onboardingOpen = _(selectOnboardingOpen)
  const shop = _(selectShop)
  const shouldRedirectToAuthenticate = _(selectShouldAuthenticateUser)

  useEffect(() => {
    if (onboardingOpen) {
      if (!document.documentElement.style.overflow) document.documentElement.style.overflow = 'hidden'
    } else {
      if (document.documentElement.style.overflow) document.documentElement.style.overflow = ''
    }
  }, [onboardingOpen])

  const showPlansScreen = shop.forceSelectSubscriptionPlan
  const onboardingComplete = shop.onboardingComplete
  const revealPage =
    showPlansScreen !== undefined && onboardingComplete !== undefined && !onboardingOpen && !showPlansScreen
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <>
            <ShopifyNavigation />
            <Frame>
              <Suspense>
                <Beacon />
                <LoginAsUrl />
                <PageRevealer reveal={revealPage}>
                  <NavigationLoader />
                  <Outlet />
                </PageRevealer>
                {!revealPage && (
                  <>
                    {onboardingOpen && <OnboardingLoader />}
                    {showPlansScreen && <PlansPageLoader force={true} />}
                  </>
                )}
              </Suspense>
              <GlobalToasts />
            </Frame>
          </>
        }
      >
        {['/', '/shopify/shop/login'].map((path) => (
          <Route key={path} path={path} element={<DashboardLoader />} />
        ))}
        <Route path="/returns/create" element={<CreateReturnPageLoader />} />
        <Route path="/returns" element={<ReturnsPageLoader />} />
        <Route path="/return/:id" element={<ReturnDetailPageLoader />} />
        <Route path="/rma/:id" element={<RMALoader />} />
        <Route path="/config-settings/*" element={<SettingsRouter />} />
        <Route path="/help-and-support" element={<HelpAndSupportPageLoader />} />
      </Route>,
    ),
  )

  if (shouldRedirectToAuthenticate && !getToken() && !shop.isUitestShop) return <AuthenticateUser />
  return <RouterProvider router={router} />
}

export default App
