import { dehydrateDateFilter, IDateFilter, rehydrateDateFilter } from './date'
import { DATE_FIELDS, DateFieldKey, IReturnFilters } from './filters'

export type ReturnsView = {
  id: string
  name: string
  filters: Partial<IReturnFilters>
}

export const viewIdFromName = <Name extends string>(name: Name): `custom-view-${string}` =>
  `custom-view-${name.toLowerCase().split(' ').join('-')}`

export type DehydratedViewFilters = Partial<Omit<IReturnFilters, DateFieldKey> & { [Field in DateFieldKey]: string }>
export type DehydratedReturnsView = Omit<ReturnsView, 'filters'> & { filters: DehydratedViewFilters }

const dehydrateFilters = (paramFilters: Partial<IReturnFilters>) => {
  const dehydratedDates = DATE_FIELDS.reduce(
    (result, fieldName) =>
      paramFilters[fieldName]
        ? {
            ...result,
            [fieldName]: dehydrateDateFilter(paramFilters[fieldName], false),
          }
        : result,
    {} as { [Prop in DateFieldKey]: string },
  )
  return {
    ...paramFilters,
    ...dehydratedDates,
  }
}
const rehydrateFilters = (dehidratedFilters: DehydratedViewFilters) => {
  const hydratedDateFilters = DATE_FIELDS.reduce(
    (result, fieldName) =>
      dehidratedFilters[fieldName]
        ? {
            ...result,
            [fieldName]: rehydrateDateFilter(dehidratedFilters[fieldName]),
          }
        : result,
    {} as { [Prop in DateFieldKey]: IDateFilter },
  )
  return {
    ...dehidratedFilters,
    ...hydratedDateFilters,
  }
}
export const dehydrateViews = (views: ReturnsView[]): DehydratedReturnsView[] =>
  views.map((v) => ({ ...v, filters: dehydrateFilters(v.filters) }))
export const rehydrateViews = (views: DehydratedReturnsView[]): ReturnsView[] =>
  views.map((v) => ({ ...v, filters: rehydrateFilters(v.filters) }))
