import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { dehydrateDateFilter } from 'returns/models/date'

import {
  DATE_FIELDS,
  DEFAULT_FILTERS,
  FilterKeys,
  FILTERS_PROPS,
  IReturnFilters,
  NUMBER_ARRAY_FIELDS,
  STRING_ARRAY_FIELDS,
} from '../models/filters'
import { useCurrentView } from './useCurrentView'
import { useParamFilters } from './useParamFilters'
import { useViews } from './useViews'

type Nullable<T> = { [K in keyof T]: T[K] | null }
type NullableIReturnFilters = Nullable<IReturnFilters>
export type FiltersPatch = Partial<NullableIReturnFilters>

export const useFilters = () => {
  const [, setSearchParams] = useSearchParams()

  const views = useViews()
  const [currentView] = useCurrentView()
  const view = views.find((v) => v.id === currentView)

  const paramFilters = useParamFilters()

  const filters = useMemo(
    () => ({ ...DEFAULT_FILTERS, ...(view?.filters ?? paramFilters) }),
    [currentView, view?.filters, paramFilters],
  )

  const patchFilters = useCallback(
    (patch: FiltersPatch) => {
      setSearchParams(
        (prev) => {
          prev.delete('view')
          const allNewFilters = { ...view?.filters, ...patch }
          const keys = Object.keys(allNewFilters) as FilterKeys[]
          for (const key of keys) {
            if (DATE_FIELDS.includes(key as any)) {
              const newDate = allNewFilters[key as (typeof DATE_FIELDS)[number]]
              if (newDate) prev.set(key, dehydrateDateFilter(newDate))
              else prev.delete(key)
              continue
            }
            if (STRING_ARRAY_FIELDS.includes(key as any)) {
              const newValues = allNewFilters[key] as string[] | null
              if (newValues?.length) prev.set(key, newValues.join('~'))
              else prev.delete(key)
              continue
            }
            if (NUMBER_ARRAY_FIELDS.includes(key as any)) {
              const newValues = allNewFilters[key as (typeof NUMBER_ARRAY_FIELDS)[number]]
              if (newValues?.length) prev.set(key, newValues.join('_'))
              else prev.delete(key)
              continue
            }
            const newValue = allNewFilters[key] as string | null
            if (newValue) prev.set(key, newValue)
            else prev.delete(key)
          }
          return prev
        },
        { replace: true },
      )
    },
    [setSearchParams, view?.filters],
  )

  const clearFilters = useCallback(
    (includingQuery = false) => {
      setSearchParams(
        (prev) => {
          const props = includingQuery ? FILTERS_PROPS : FILTERS_PROPS.filter((p) => p !== 'query')
          props.forEach((p) => prev.delete(p))
          prev.delete('view')
          return prev
        },
        { replace: true },
      )
    },
    [setSearchParams],
  )

  return { filters, patchFilters, clearFilters }
}
