import { useMemo } from 'react'
import { selectAllShopStagesSorted, selectFormatter, selectUseApprovals } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { ArchiveFilter, ResolvedStatus, SystemStage } from 'gql'
import { ReturnsView } from 'returns/models/view'
import { notEmpty, toNumber } from 'utils'

export const useFixedViews = () => {
  const fmt = _(selectFormatter)

  const useApprovals = _(selectUseApprovals)
  const stages = _(selectAllShopStagesSorted)
  const pendingApprovalStage = useMemo(
    () => stages?.find((s) => s.systemStage === SystemStage.PendingApproval),
    [stages],
  )
  const showPendingApproval = !!pendingApprovalStage && useApprovals
  const pendingApprovalId = pendingApprovalStage?.id

  return useMemo<ReturnsView[]>(
    () =>
      [
        {
          id: 'active',
          name: fmt('status.active'),
          filters: {},
        },
        showPendingApproval
          ? {
              id: 'pending-approval',
              name: fmt('filters.approve'),
              filters: { stages: [toNumber(pendingApprovalId!)] },
            }
          : null,
        {
          id: 'pending-resolution',
          name: fmt('filters.pendingResolutionTab'),
          filters: { resolvedStatus: [ResolvedStatus.Unresolved, ResolvedStatus.PartiallyResolved] },
        },
        {
          id: 'archived',
          name: fmt('global.archived'),
          filters: { status: ArchiveFilter.Archived },
        },
        {
          id: 'all',
          name: fmt('global.all'),
          filters: { status: ArchiveFilter.Both },
        },
      ].filter(notEmpty),
    [fmt, showPendingApproval, pendingApprovalId],
  )
}
