import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { rehydrateDateFilter } from 'returns/models/date'
import {
  DATE_FIELDS,
  DateFieldKey,
  FilterKeys,
  FILTERS_PROPS,
  IReturnFilters,
  NUMBER_ARRAY_FIELDS,
  STRING_ARRAY_FIELDS,
} from 'returns/models/filters'
import { toNumber } from 'utils'

const hidrateFilterParams = (filterParams: Partial<Record<FilterKeys, string>>) => {
  const result: Partial<IReturnFilters> = {}
  const keys = Object.keys(filterParams) as FilterKeys[]
  for (const key of keys) {
    if (DATE_FIELDS.includes(key as any)) {
      const dateKey = key as DateFieldKey
      result[dateKey] = rehydrateDateFilter(filterParams[dateKey]!)
      continue
    }
    if (STRING_ARRAY_FIELDS.includes(key as any)) {
      const arrayKey = key as (typeof STRING_ARRAY_FIELDS)[number]
      const string = filterParams[arrayKey]!
      result[arrayKey] = string.split('~') as any[]
      continue
    }
    if (NUMBER_ARRAY_FIELDS.includes(key as any)) {
      const arrayKey = key as (typeof NUMBER_ARRAY_FIELDS)[number]
      const string = filterParams[arrayKey]!
      result[arrayKey] = string.split('_').map(toNumber)
      continue
    }
    ;(result[key] as string) = filterParams[key] as string
  }
  return result
}

export const useParamFilters = () => {
  const [searchParams] = useSearchParams()

  // This filterDeps array is used to only change the identity of the `paramFilters` object
  // if its contents actually changed, rather than every time the `searchParams` change.
  const filterDeps = useMemo(() => FILTERS_PROPS.map((prop) => searchParams.get(prop)), [searchParams])
  return useMemo(
    () =>
      hidrateFilterParams(
        FILTERS_PROPS.reduce(
          (result, prop) => (searchParams.get(prop) === null ? result : { ...result, [prop]: searchParams.get(prop) }),
          {} as { [key in FilterKeys]: string },
        ),
      ),
    filterDeps,
  )
}
