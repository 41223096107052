import { useMemo } from 'react'

import { useCustomViews } from './useCustomViews'
import { useFixedViews } from './useFixedViews'

export const useViews = () => {
  const fixedViews = useFixedViews()
  const [customViews] = useCustomViews()
  return useMemo(() => [...fixedViews, ...customViews], [fixedViews, customViews])
}
