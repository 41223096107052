import { endOfDay, startOfDay, subDays } from 'date-fns'
import { Formatter } from 'store/global/global.selectors'

import { toNumber } from 'utils'

export const CUSTOM_DATE = 'custom' as const
export const getDateFilterOptions = (fmt: Formatter) => ({
  '1': { label: fmt('dateFilter.today'), value: '1' },
  '7': { label: fmt('dateFilter.last7Days'), value: '7' },
  '30': { label: fmt('dateFilter.last30Days'), value: '30' },
  '90': { label: fmt('dateFilter.last90Days'), value: '90' },
  '365': { label: fmt('dateFilter.last12Months'), value: '365' },
  [CUSTOM_DATE]: { label: fmt('dateFilter.custom'), value: CUSTOM_DATE },
})
export type IDateFilterOptions = keyof ReturnType<typeof getDateFilterOptions>
export interface IDateFilter {
  since: null | IDateFilterOptions
  start: Date
  end: Date
}

export const generateRange = (days: string) => {
  const end = endOfDay(new Date())
  const start = startOfDay(subDays(end, toNumber(days)))
  return { start, end }
}

export const dehydrateDateFilter = (date: IDateFilter, keepTimestamps = true) => {
  const since = date.since
  const start = date.start.getTime()
  const end = date.end.getTime()
  return since ? (since === CUSTOM_DATE || keepTimestamps ? `${since}_${start}_${end}` : since) : `${start}_${end}`
}

export const rehydrateDateFilter = (dehydratedDate: string) => {
  const parts = dehydratedDate.split('_')
  switch (parts.length) {
    case 3:
      return {
        since: parts[0] as IDateFilterOptions,
        start: new Date(parseInt(parts[1])),
        end: new Date(parseInt(parts[2])),
      }
    case 2:
      return {
        since: null,
        start: new Date(parseInt(parts[0])),
        end: new Date(parseInt(parts[1])),
      }
    default:
      return {
        since: parts[0] as IDateFilterOptions,
        ...generateRange(parts[0]),
      }
  }
}
