import { Loading } from '@shopify/polaris'
import { FC, lazy, useCallback, useEffect, useMemo } from 'react'

import { useCommonData } from 'common/useCommonData'
import { useSessionState } from 'common/useSessionState'
import { useReturnListPageQuery } from 'gql'
import { notEmpty } from 'utils'

import { useRequestFilters } from './hooks/useRequestFilters'

const ReturnsPage = lazy(() => import('./ReturnsPage'))

export const LATEST_RETURNS_RESULTS_KEY = 'rz-latest-returns-results'

export const ReturnsPageLoader: FC = () => {
  const requestFilters = useRequestFilters()
  const [{ data, fetching, stale }, refreshQuery] = useCommonData(
    useReturnListPageQuery,
    { variables: requestFilters },
    'returns',
  )
  const refresh = useCallback(() => refreshQuery({ requestPolicy: 'network-only' }), [refreshQuery])

  const latestResults = useMemo(
    () => data?.returnList?.returns.filter(notEmpty).map((r) => r.id),
    [data?.returnList?.returns],
  )
  const [, setLatestResults] = useSessionState(LATEST_RETURNS_RESULTS_KEY, latestResults)
  useEffect(() => latestResults && setLatestResults(latestResults), [latestResults])

  return (
    <>
      {(fetching || stale) && <Loading />}
      <ReturnsPage returnList={data?.returnList} refresh={refresh} />
    </>
  )
}
