import { useMemo } from 'react'

import { useCurrentPage } from './useCurrentPage'
import { useFilters } from './useFilters'
import { usePerPage } from './usePerPage'
import { useSortBy } from './useSortBy'

export const useRequestFilters = () => {
  const [perPage] = usePerPage()
  const [currentPage] = useCurrentPage()
  const [sortBy] = useSortBy()
  const { filters } = useFilters()
  return useMemo(() => ({ perPage, currentPage, sortBy, ...filters }), [perPage, currentPage, sortBy, filters])
}
