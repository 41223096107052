import { endOfDay, startOfDay } from 'date-fns'

import { ArchiveFilter, ResolvedStatus, ReturnType } from 'gql'
import { Implements } from 'utils'

import { IDateFilter } from './date'

export const FILTERS_PROPS = [
  'query',
  'returnItemTypes',
  // 'shippingMethod',
  'stages',
  'resolvedStatus',
  'status',
  'returnDate',
  'orderDate',
  'stageUpdatedDate',
] as const
export type FilterKeys = (typeof FILTERS_PROPS)[number]
export type AllFilterProps = Record<FilterKeys, Exclude<unknown, undefined>>
export type IReturnFilters = Implements<
  AllFilterProps,
  {
    query: string
    returnItemTypes: ReturnType[]
    // shippingMethod?: ShippingMethod[]
    stages: number[]
    resolvedStatus: ResolvedStatus[]
    status: ArchiveFilter
    returnDate: IDateFilter
    orderDate: IDateFilter
    stageUpdatedDate: IDateFilter
  }
>
export const DEFAULT_FILTERS: IReturnFilters = {
  query: '',
  returnItemTypes: [],
  // shippingMethod: [],
  stages: [],
  resolvedStatus: [],
  status: ArchiveFilter.Active,
  returnDate: {
    since: null,
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
  orderDate: {
    since: null,
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
  stageUpdatedDate: {
    since: null,
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  },
}

export const DATE_FIELDS = ['returnDate', 'orderDate', 'stageUpdatedDate'] as const
export type DateFieldKey = (typeof DATE_FIELDS)[number]
export const STRING_ARRAY_FIELDS = ['returnItemTypes', 'resolvedStatus'] as const
export const NUMBER_ARRAY_FIELDS = ['stages'] as const
